
/* @-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
   }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
   }
}
@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
   }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
   }
}
.ticker-wrap {
    position: sticky;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #3e4192;
    padding-left: 100%;
    box-sizing: content-box;
}
.ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 15s;
    animation-duration: 15s;
}
.ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 32px;
    color: white;
} */


@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
@keyframes ticker {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
.ticker-wrap {
    position: sticky;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 2.9rem;
    background-color: #3e4192;
    transform: rotate(-3deg); 
    margin-top: -27px;
    box-shadow: 0px 9px 9px 2px #a4a1a1;
}
.ticker {
    display: flex;
    white-space: nowrap;
    animation: ticker 10s linear infinite;
    line-height: 2.9rem;
}
.ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 32px;
    color: white;
    font-family: 'Poppins';
    font-weight: 300;
    font-style: italic;
}


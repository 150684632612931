.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.img-item {
  position: relative;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 35px 20px 20px 20px;
  background-color: #f1f1f1;
  height: 650px;
  width: 100%;
}

.img-item:hover {
  background: #3e4192;
}

.img-item img {
  width: 100%;
  border: 8px solid #3e4192;
  height: 350px;
  object-fit: cover;
}

.img-item:hover .caption h3 {
  color: #fff;
}

.img-item:hover .caption .read-more {
  background-color: #fff;
  color: #3e4192;
}

.caption {
  padding: 15px;
  text-align: center;
  color: #000;
}

.caption h3 {
  margin: 25px 0 20px;
  font-size: 24px;
  font-weight: 500;
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 70px;
}

.caption .read-more {
  display: inline-block;
  padding: 10px 20px;
  background: #3e4192;
  color: #fff;
  border-radius: 15px;
  text-decoration: none;
  transition: background 0.3s ease;
  font-size: 20px;
  font-weight: 500;
}

.caption .read-more:hover {
  background: #3e4192;
  color: #fff;
  border-color: #fff;

}

/* Custom style for the active slide */
/* .swiper-slide-active .img-item img {
  border:8px solid #ffffff;
}
.swiper-slide-active .img-item{
  background-color: #3e4192;
  padding: 35px;
}
.swiper-slide-active .caption h3{
  color: #fff;
} */
.swiper-pagination {
  top: 0 !important;
}

.gallery-slider {
  padding-top: 40px;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  width: 100px;
  border-radius: 4px;
}

.swiper-pagination-bullet-active {
  background-color: #3e4192 !important;
}

.swiper-wrapper {
  margin-top: 60px;
}

/* .swiper-slide-active .caption .read-more{
    background: #fff;
    color: #3e4192;
  } */

/* .swiper-pagination-bullet {
    width: calc(100% / var(--swiper-pagination-bullets)) !important;
} */

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  height: 20px;
}